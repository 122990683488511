import React from 'react';
import { Mail, BrandLinkedin, BrandGithub, Movie, Code, Database, BrandPython, BrandHtml5, BrandCss3, BrandApple, BrandReact } from 'tabler-icons-react';

const Landing = () => {
  return (
    <div className="font-sans bg-[#285DEF]">
      {/* Profile Section */}
      <section className="bg-[#262333] text-white p-4 sm:p-8 md:p-10 lg:p-16 xl:p-24">
        <div className="container mx-auto px-4 xl:px-8 2xl:px-16 flex flex-wrap items-center">
          <div className="w-full md:w-1/3 mb-8 md:mb-0 flex flex-col items-center">
            <img 
              src="/images/profile.jpg" 
              alt="Max Ihnen" 
              className="rounded-full mb-8 w-48 h-48 sm:w-64 sm:h-64 lg:w-80 lg:h-80 object-cover shadow-lg" 
            />
            <div className="bg-purple-600 rounded-full px-4 sm:px-6 py-2 sm:py-3 flex space-x-4 sm:space-x-6">
              <a href="https://www.linkedin.com/in/ihnen/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 transition-colors">
                <BrandLinkedin size={24} className="sm:w-8 sm:h-8" />
              </a>
              <a href="mailto:maxihnen@gmail.com" className="hover:text-gray-200 transition-colors">
                <Mail size={24} className="sm:w-8 sm:h-8" />
              </a>
              <a href="https://github.com/maxdenuevo" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 transition-colors">
                <BrandGithub size={24} className="sm:w-8 sm:h-8" />
              </a>
              <a href="https://letterboxd.com/cinenauta/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200 transition-colors">
                <Movie size={24} className="sm:w-8 sm:h-8" />
              </a>
            </div>
          </div>
          <div className="w-full md:w-2/3 flex items-center justify-center md:justify-end">
            <pre className="text-left text-sm sm:text-base md:text-lg lg:text-xl bg-gray-800 p-4 sm:p-6 lg:p-8 rounded-lg max-w-lg">
              <code className="text-green-400">
{`{
  "name": "max",
  "occupation": "full stack dev",
  "likes": ["movies", "tacos"]
}`}
              </code>
            </pre>
          </div>
        </div>
      </section>

      {/* Skillset Section */}
      <section className="bg-[#285DEF] p-4 sm:p-8 md:p-10 lg:p-16 xl:p-24">
        <div className="container mx-auto px-4 xl:px-8 2xl:px-16">
          <h2 className="text-black text-3xl sm:text-4xl font-inter mb-6 sm:mb-8 lg:mb-12">Skillset</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 sm:gap-6 lg:gap-8">
            {[
              // Backend & Core
              { name: 'Python', icon: <BrandPython size={64} /> },
              { name: 'Flask', icon: <Code size={64} /> },
              { name: 'SQL', icon: <Database size={64} /> },
              { name: 'PostgreSQL', icon: <Database size={64} /> },
              { name: 'AWS', icon: <Code size={64} /> },
              { name: 'Git', icon: <Code size={64} /> },
              // Testing & Development
              { name: 'Jest', icon: <Code size={64} /> },
              // Frontend & Web
              { name: 'JavaScript', icon: <Code size={64} /> },
              { name: 'React', icon: <BrandReact size={64} /> },
              { name: 'Tailwind', icon: <Code size={64} /> },
              // Combined HTML/CSS as Web Fundamentals
              { name: 'HTML/CSS', icon: <BrandHtml5 size={64} /> },
              { name: 'Swift', icon: <BrandApple size={64} /> },
            ].map((skill, index) => (
              <div key={index} className="bg-white p-3 sm:p-4 lg:p-6 rounded-lg flex flex-col items-center justify-center aspect-square hover:shadow-lg transition-shadow">
                {React.cloneElement(skill.icon, { className: 'w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24' })}
                <span className="mt-2 sm:mt-4 text-black text-center text-xs sm:text-sm lg:text-base">{skill.name}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;