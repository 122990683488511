import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Mail, BrandLinkedin, BrandGithub, Movie } from 'tabler-icons-react';
import LandingPage from './components/Landing';  // Changed this line
import CV from './components/CV';
import Portfolio from './components/Portfolio';  // This is the new Portfolio component

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen">
        <nav className="bg-[#285DEF] p-4 sm:p-5 md:p-5 lg:p-6 fixed top-0 left-0 right-0 z-10">
          <div className="container mx-auto flex justify-between items-center px-4 xl:px-8 2xl:px-16">
            <Link to="/" className="text-black text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold">Max Ihnen</Link>
            <div>
              <Link to="/" className="text-black font-bold text-lg sm:text-xl mr-4 sm:mr-6">Home</Link>
              <Link to="/portfolio" className="text-black font-bold text-lg sm:text-xl mr-4 sm:mr-6">Portfolio</Link>
              <Link to="/cv" className="text-black font-bold text-lg sm:text-xl">CV</Link>
            </div>
          </div>
        </nav>

        <main className="flex-grow mt-16 sm:mt-[76px] md:mt-[80px] lg:mt-[88px]">
          <Routes>
            <Route path="/" element={<LandingPage />} />  // Changed this line
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/cv" element={<CV />} />
          </Routes>
        </main>



        <footer className="bg-[#262333] text-white p-4">
          <div className="container mx-auto flex flex-col items-center">
            <div className="flex justify-center space-x-4 mb-2">
              <a href="https://www.linkedin.com/in/ihnen/" target="_blank" rel="noopener noreferrer">
                <BrandLinkedin size={24} />
              </a>
              <a href="mailto:maxihnen@gmail.com">
                <Mail size={24} />
              </a>
              <a href="https://github.com/maxdenuevo" target="_blank" rel="noopener noreferrer">
                <BrandGithub size={24} />
              </a>
              <a href="https://letterboxd.com/cinenauta/" target="_blank" rel="noopener noreferrer">
                <Movie size={24} />
              </a>
            </div>
            <div className="text-sm">© 2024 Max Ihnen</div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;